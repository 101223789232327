import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import { Container, Row, Col } from "../components/Grid"
import Intro from "../components/Intro"
import Text from "../components/Text"

import Icon from "../images/undraw_navigator_a479.svg"

const PortfolioPage = () => (
  <Layout>
    <SEO title="Experiência" />

    <Intro
      full={false}
      title="Um pouco sobre os meus trabalhos anteriores e onde estudei"
      img={{
        src: Icon,
        alt: "",
      }}
    />

    <Container full>
      <Row alignItems="center">
        <Col size="12" md="2"></Col>
        <Col size="12" md="8">
          <Text as="h2" color="cloud_light" mt="30" mb="30">
            Profissional
          </Text>
          <ul>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Front-End Developer PL
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Paraná Banco S/A
              </Text>
              <Text color="cloud_dark" size="xs">
                Mar 2020 - Atual
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Front-End Developer PL
              </Text>
              <Text color="cloud" size="sm" mb="4">
                VHSYS - Sistema de Gestão Empresarial Online Tempo integral
              </Text>
              <Text color="cloud_dark" size="xs">
                Jun 2019 - Fev 2020
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Programador JR
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Grupo Negócios Públicos
              </Text>
              <Text color="cloud_dark" size="xs">
                Ago 2016 - Fev 2019
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Desenvolvedor de aplicações web
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Ledupa Software
              </Text>
              <Text color="cloud_dark" size="xs">
                Nov 2015 - Jun 2016
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Desenvolvedor de aplicações web
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Dohms Web
              </Text>
              <Text color="cloud_dark" size="xs">
                Nov 2014 - Out 2015
              </Text>
            </Text>
          </ul>
        </Col>
      </Row>

      <Row alignItems="center">
        <Col size="12" md="2"></Col>
        <Col size="12" md="8">
          <Text as="h2" mt="30" mb="30">
            Educação
          </Text>
          <ul>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Go Stack 08
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Rocketseat
              </Text>
              <Text color="cloud_dark" size="xs">
                2019 - Atual
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Gama Experience #20 - Hacker, Front-end Development
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Gama Academy
              </Text>
              <Text color="cloud_dark" size="xs">
                2019
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Produção Multimídia
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Grupo Educacional Opet
              </Text>
              <Text color="cloud_dark" size="xs">
                2015 - 2017
              </Text>
            </Text>
            <Text as="li" mb="32" color="cloud_light">
              <Text size="md" weight="bold" mb="8">
                Técnico em Informática
              </Text>
              <Text color="cloud" size="sm" mb="4">
                Senai PR
              </Text>
              <Text color="cloud_dark" size="xs">
                2013 - 2014
              </Text>
            </Text>
          </ul>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default PortfolioPage
